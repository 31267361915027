@import "../../variables";

$height: calc(100vh - $navbarHeight);
$heightAfterZoom: calc(110vh - $navbarHeight);

@keyframes zom-in {
    from{
        height: $height;
    }
    to{
        height: $heightAfterZoom;
    }
}
.cover {
    height: $height;
    overflow: hidden;
    position: relative;

    >*{
        position: absolute;
        top: 0;
        height: $height;
        width: 100vw;
    }

    .image-cover{
        object-fit: cover;
        filter: brightness(.5);
        border: none;
        transition: transform .3s;

        &.last-image{
            transform: translateX(-100%);
        }

        &.current-image{
            transform: none;
            animation-name: zom-in;
            animation-duration: 8s;
            animation-timing-function: ease-in-out;
            height: $heightAfterZoom;
        }

        &.next-image{
            transform: translateX(100%);
        }
    }

    .logo{
        display: flex;
        justify-content: center;
        align-items: center;

        img{
            width: 300px;
        }
    }

    .social-network img{
        position: absolute;
        cursor: pointer;

        $padding: 40px;
        bottom: $padding;
        right: $padding;

        transition: filter .3s;

        &:hover{
            filter: invert(.3);
        }
    }
}

@import '../../variables';

.about-us {
    padding: 40px 0;
    background-color: var(--color-primary-background);

    display: flex;
    align-items: center;

    overflow: hidden;
    $scale: scale(1.7);

    .mask {
        img {
            width: 27vw;
            filter: contrast(0) opacity(.6);
        }

        &.mask-left {
            transform: $scale translateX(-30%);
        }

        &.mask-right {
            transform: $scale translateX(20%);
        }
    }

    .content {
        padding: 32px 48px;
        text-align: center;

        .title {
            padding: 40px 0;
            font-size: 1.8rem;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.9rem;

            position: relative;
            color: black;

            &:after {
                content: " ";
                position: absolute;
                width: 72px;
                height: 6px;
                background-color: var(--color-second-accent);

                bottom: 16px;
                left: 50%;
                transform: translateX(-50%);
            }

        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        background-color: unset;
        background-image: linear-gradient(rgba(176, 173, 149, 0.8), rgba(176, 173, 149, 0.8)), url('/assets/images/mask-cellphone.png');

        .mask {
            display: none;
        }
    }
}

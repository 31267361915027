@import "../../../variables";

nav{
    padding: $navbarPaddingHeight 16px;
    background: black;

    display: flex;
    justify-content: flex-end;
    align-items: center;

    color: white;

    .action{

        a{
            text-decoration: none;
        }

        .outline-btn{
            padding: 8px 24px;
            border: 2px solid white;
            font-weight: bold;
            background-color: #ffffff22;
            font-size: .9rem;
            cursor: pointer;
            color: white;

            transition: border-bottom-color, border-left-color, border-top-color, border-right-color, color, background-color .3s;

            &:hover{
                background: var(--color-primary-background);
                color: #261810;
                border: 2px solid var(--color-primary-background);
            }
        }
    }

}

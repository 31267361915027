:root {
    --color-primary-background: #b0ad95;
    --color-font: #261810;
    --color-accent: #79838d;
    --color-second-accent: #8d601e;
    --color-second-light-accent: #b68f38;
}

@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: syne;
    src: url(assets/fonts/syne/Syne-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-Regular.ttf) format('truetype');
    font-weight: 400;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-Medium.ttf) format('truetype');
    font-weight: 500;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-SemiBold.ttf) format('truetype');
    font-weight: 600;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-Bold.ttf) format('truetype');
    font-weight: 700;
}

@font-face {
    font-family: rubik;
    src: url(assets/fonts/rubik/Rubik-ExtraBold.ttf) format('truetype');
    font-weight: 800;
}



body {
    margin: 0;
    font-family: syne, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body, p, h1, h2, h3, h4, h5, h6, span, div{
    color: var(--color-font);
}

@import '../../variables';

.outro {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('/assets/images/outro.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: 75% 5%;
    background-repeat: no-repeat;

    text-align: center;

    img {
        width: 35vw;
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3)  {
        padding: 120px 0;
        img {
            width: 50vw;
        }
    }

    @supports (-webkit-touch-callout: none) {
        background-attachment: scroll;
    }
}

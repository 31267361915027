.footer{
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 48px;

    .text{
        color: var(--color-second-accent);

        font-size: .8rem;
    }

    .actions{
        img{
            width: 24px;

            filter: invert(24%) sepia(22%) saturate(1322%) hue-rotate(358deg) brightness(96%) contrast(86%);

            &:hover {
                filter: invert(34%) sepia(72%) saturate(517%) hue-rotate(356deg) brightness(97%) contrast(85%);
            }
        }
    }
}

@import '../../variables';

.address {

    background-image: url('/assets/images/red-background.png');

    position: relative;

    padding: 64px;

    &:after {
        position: absolute;

        content: " ";
        width: 2px;
        height: 64px;
        background-color: var(--color-second-light-accent);
        left: 50%;
        top: 0;
        transform: translateY(-50%);
    }

    h1 {
        margin: 0 0 42px 0;
        color: white;

        text-align: center;
        font-size: 3rem;
        position: relative;

        &:before {
            content: " ";
            position: absolute;
            width: 72px;
            height: 6px;
            background-color: var(--color-second-accent);

            bottom: -32px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .content {
        padding: 40px 0;
        display: flex;
        justify-content: center;

        .info {
            padding: 0 24px;

            > * {
                padding: 8px 0;
                display: flex;
                align-items: center;
            }

            img {
                filter: invert(1);
                margin: 0 8px;
            }

            a, span {
                color: white;
                white-space: pre-wrap;
            }

            .map {
                margin-left: 8px;
                width: 400px;
                height: 260px;
                border: none;
            }

            .schedule {
                img {
                    filter: invert(11%) sepia(69%) saturate(7383%) hue-rotate(359deg) brightness(111%) contrast(112%);
                }

                span {
                    color: red;
                }

                &.isOpen {
                    img {
                        filter: invert(23%) sepia(96%) saturate(1832%) hue-rotate(105deg) brightness(100%) contrast(104%)
                    }

                    span {
                        color: #008000;
                    }
                }
            }
        }

        .portfolio {
            display: flex;
            flex-wrap: wrap;
            max-width: 50vw;
            justify-content: space-around;

            .wrap-img {
                $size: 180px;
                $width: $size;
                $height: $size / 8 * 5;

                overflow: hidden;
                width: $width;
                height: $height;

                margin: 8px;

                position: relative;

                img {
                    width: $width;
                    height: $height;
                    object-fit: cover;

                    cursor: pointer;

                    transition-duration: .3s;
                    transition-property: transform;
                    transition-timing-function: ease-in-out;
                }

                &:hover {
                    img {
                        transform: scale(1.1);
                    }
                }

                &::after {
                    $padding: 16px;
                    content: ' ';
                    width: $width - $padding;
                    height: $height - $padding;
                    border: 3px var(--color-second-light-accent) solid;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);

                    cursor: pointer;
                }
            }
        }
    }

    .popup {
        position: fixed;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        backdrop-filter: blur(10px);

        display: flex;
        justify-content: center;


        .container {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;

            .photo {
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    max-height: 80vh;
                    max-width: 80vw;
                }
            }

            $marginClickIcon: 24px;
            .next, .back{
                cursor: pointer;
                img {
                    width: 48px;
                    filter: invert(1);
                }

                &:hover img{
                    filter: invert(.9);
                }
            }
            .next {
                margin-left: $marginClickIcon;
            }

            .back {
                margin-right: $marginClickIcon;
            }
        }
    }

    @media only screen and (max-width: $mobileWidth), (-webkit-min-device-pixel-ratio: 3) {
        $horizontalPadding: 24px;
        padding: 64px $horizontalPadding;

        .popup{
            display: none;
        }

        .content {
            flex-direction: column;

            .info {
                padding: 0;

                .map {
                    width: calc(100vw - #{$horizontalPadding * 2});
                    padding: 0;
                    margin: 32px 0;
                }
            }

            .portfolio {
                max-width: unset;

                .wrap-img {
                    $size: 100%;
                    $width: $size;
                    $height: $size / 8 * 5;

                    width: $width;
                    height: unset;

                    margin: 8px 0;

                    img {
                        width: $width;
                        height: unset;
                    }

                    &::after {
                        $padding: 24px;
                        width: calc(100% - #{$padding});
                        height: calc(100% - #{$padding});
                    }

                }
            }
        }
    }
}

@import '../../variables';

.houseMenu {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0;
    background-color: var(--color-primary-background);
    overflow: hidden;

    $scale: scale(1.7);

    .mask {
        img {
            width: 27vw;
            filter: contrast(0) opacity(.6);
        }

        &.mask-left {
            transform: $scale translateX(-30%);
        }

        &.mask-right {
            transform: $scale translateX(20%);
        }
    }


    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        margin-bottom: 40px;


        .title {
            padding: 40px 0;
            font-size: 1.8rem;
            font-weight: bold;
            text-transform: uppercase;
            line-height: 1.9rem;
            color: black;

            text-align: center;
        }

        .images {
            display: flex;
            margin-bottom: 40px;

            .wrap-img {
                position: relative;
                border-radius: 5%;

                $size: 220px;
                $width: $size;
                $height: $size / 8 * 5;

                overflow: hidden;
                width: $width;
                height: $height;

                margin: 8px;

                img {
                    width: $width;
                    height: $height;
                    object-fit: cover;
                    filter: brightness(50%);
                }

                .typical {
                    position: absolute;
                    width: 100%;
                    text-align: center;
                    top: 42%;

                    color: white;
                    font-size: 18px;
                    font-weight: 700;
                }
            }
        }

        .action {
            display: flex;
            justify-content: center;

            a {
                text-decoration: none;
            }

            .outline-btn {
                padding: 8px 24px;
                border: 2px solid var(--color-second-accent);
                font-weight: bold;
                background-color: #ffffff22;
                font-size: .9rem;
                cursor: pointer;
                color: var(--color-second-accent);

                transition: border-bottom-color, border-left-color, border-top-color, border-right-color, color, background-color .3s;

                &:hover {
                    background: var(--color-second-accent);
                    color: white;
                    border: 2px solid var(--color-primary-background);
                }
            }

        }

    }
}